<template>
  <Layout>
    <DispatcherQuickLook :date="date" />
  </Layout>
</template>

<script>
import Layout from '@layouts/fullHeight'
import DispatcherQuickLook from '@dispatcher/DispatcherQuickLook'
import dayjs from 'dayjs'

export default {
  name: 'DispatcherQuickLookView',
  components: { Layout, DispatcherQuickLook },
  props: {
    date: {
      type: String,
      required: false,
      default: () => dayjs().format('MM-DD-YYYY'),
    },
  },
  metaInfo: {
    title: 'Dispatcher',
  },
}
</script>
