<template>
  <VLayout row wrap>
    <VFlex
      ><h4 class="list-title">{{ title }} ( {{ assets.length }} )</h4></VFlex
    >
    <VFlex>
      <VLayout row wrap class="asset-tile-list">
        <template v-for="asset of assets">
          <VFlex
            :key="asset.ID"
            sm12
            :class="['asset-tile', { assigned: assigned, availabe: !assigned }]"
          >
            <div min-width="100%" flat class="mx-1 px-1 left text-xs-left"
              ><slot name="asset" :asset="asset">{{
                JSON.stringify(asset)
              }}</slot></div
            >
          </VFlex>
        </template>
      </VLayout>
    </VFlex>
  </VLayout>
</template>

<script>
export default {
  name: 'AssetOverviewList',
  props: {
    assets: {
      type: Array,
      required: true,
      default: () => [],
    },
    title: {
      type: String,
      default: 'Title',
    },
    assigned: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },
}
</script>

<style lang="scss" scoped>
.list-title {
  margin: 1em 0 0.5em;
  text-align: left;
  text-transform: uppercase;
  letter-spacing: 0.03em;
}
.asset-tile {
  padding: 0;
  line-height: 1.6;
  &:not(:last-child) {
    border-bottom: 1px dotted lighten(black, 80);
  }
  &.assigned {
    // opacity: 0.4;
  }
}
</style>
