<script>
import dayjs from 'dayjs'

import { mapActions, mapGetters, createNamespacedHelpers } from 'vuex'
const { mapState: routeState } = createNamespacedHelpers('route')

export default {
  name: 'WithAssignedAssets',
  props: {
    itemsPerPage: {
      type: Number,
      default: 1000,
    },
    additionalFilters: {
      type: Array,
      default: () => [],
    },
    assetType: {
      type: String,
      required: false,
      default: '',
    },
    date: {
      type: String,
      default: () => dayjs().format('MM/DD/YYYY'), // TODO (ES) : check format for knack
    },
  },
  data() {
    return {
      areAssetsLoading: false,
    }
  },
  computed: {
    ...routeState(['query']),
    ...mapGetters(['getAssignedAssetsResponses']),
    areAnyFiltersOn() {
      let queryKeys = Object.keys(this.query)
      let hasFilterMatches = queryKeys.some((k) =>
        this.filterParams.includes(k)
      )
      return hasFilterMatches || this.sortedByDate
    }, // areAnyFiltersOn
    // full repsonse object including records and metadata too
    response() {
      return this.getAssignedAssetsResponses(
        this.assetType || this.$route.params.assetType
      )
    },
  },

  watch: {
    assetType: {
      async handler(val) {
        this.areAssetsLoading = true
        await this.fetchAssignedAssets({
          assetType: this.assetType,
          date: this.date,
          rows_per_page: this.itemsPerPage,
        })
        this.areAssetsLoading = false
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions(['fetchAssignedAssets']),
  },
  render() {
    const { response, areAssetsLoading } = this
    return this.$scopedSlots.default({
      areAssetsLoading,
      response,
      assignedAssets:
        this.getAssignedAssetsResponses(this.assetType).records || [],
    })
  },
}
</script>
