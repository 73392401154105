<template>
  <VContainer fluid class="dispatcher-quick-look" fill-height>
    <VLayout wrap>
      <VFlex sm12 class="no-print">
        <DatePagination @nextDay="nextDay" @previousDay="previousDay" />
      </VFlex>
      <VFlex sm12 md6 px-1 class="print-half">
        <VLayout row fill-height align-space-around>
          <VFlex>
            <VCard>
              <VCardTitle primary-title class="pb-0 col-title"
                ><h2>Laborers</h2>
                <VSpacer />
                <BaseButton
                  :to="{ path: `/dispatcher/${date}/laborer` }"
                  color="grey"
                  md-icon="list"
                  class="no-print"
                  >Manage</BaseButton
                ></VCardTitle
              >
              <AssetAssignmentOverview asset-type="laborer" />
            </VCard>
          </VFlex>
        </VLayout>
      </VFlex>
      <VFlex sm12 md6 px-1 class="print-half">
        <VLayout row fill-height align-space-around>
          <VFlex>
            <VCard>
              <VCardTitle primary-title class="pb-0 col-title"
                ><h2>Equipment</h2><VSpacer />
                <BaseButton
                  :to="{ path: `/dispatcher/${date}/equipment` }"
                  color="grey"
                  md-icon="list"
                  class="no-print"
                  >Manage</BaseButton
                ></VCardTitle
              >
              <AssetAssignmentOverview asset-type="equipment" />
            </VCard>
          </VFlex>
        </VLayout>
      </VFlex>
    </VLayout>
  </VContainer>
</template>

<script>
import AssetAssignmentOverview from '@dispatcher/AssetAssignmentOverview'
import DatePagination from '@components/ReportsListView/DatePagination'

import dayjs from '@utils/dayjsCustomParse'

import { createNamespacedHelpers } from 'vuex'
const { mapState: routeState } = createNamespacedHelpers('route')

export default {
  name: 'DispatcherQuickLook',
  components: { AssetAssignmentOverview, DatePagination },
  props: {
    date: {
      type: String,
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {
    ...routeState(['query']),
  },
  methods: {
    // TODO (ES) : Move this to vuex
    nextDay() {
      let date = this.date
        ? dayjs(this.date, 'MM-DD-YYYY').format('MM/DD/YYYY')
        : dayjs().format('MM/DD/YYYY')

      this.$router.push({
        params: {
          ...this.$route.params,
          date: dayjs(date)
            .add(1, 'day')
            .format('MM-DD-YYYY'),
        },
      })
    }, // nextDay

    // TODO (ES) : Move this to vuex
    previousDay() {
      let date = this.date
        ? dayjs(this.date, 'MM-DD-YYYY').format('MM/DD/YYYY')
        : dayjs().format('MM/DD/YYYY')

      this.$router.push({
        params: {
          ...this.$route.params,
          date: dayjs(date)
            .subtract(1, 'day')
            .format('MM-DD-YYYY'),
        },
      })
    }, // previousDay
  },
}
</script>

<style lang="scss">
@media print {
  .dispatcher-quick-look {
    padding: 0;
  }
  .col-title {
    padding-top: 0;
  }
  .print-half {
    flex-basis: 50% !important;
  }
}
</style>
