<template>
  <div class="asset-assignment-overview">
    <WithAllAssetsOfType
      :asset-type="assetType"
      :additional-filters="filterByExclusionFromDispatcher"
    >
      <div slot-scope="{ allAssets }">
        <!-- TODO (ES) : reverse the hierarchy of all assets vs asgn't so we can slot-scope the reportID? -->
        <div v-if="!allAssets">No Assets Available</div>
        <WithAssignedAssets
          v-else
          :asset-type="assetType"
          :date="$route.params.date"
        >
          <VContainer
            slot-scope="{ assignedAssets, areAssetsLoading }"
            class="assets-list pt-0 px-3"
          >
            <BaseSpinner v-if="areAssetsLoading" />
            <template v-else>
              <AssetOverviewList
                :assets="assignedAssets"
                title="Assigned"
                assigned
                ><template slot="asset" slot-scope="{ asset }">
                  <span
                    v-if="assetType === 'laborer'"
                    class="mr-2 font-italic"
                    >{{
                      `${asset.JOB_NUMBER} - ${asset.LOCATION_IDENTIFIER}`
                    }}</span
                  >
                  <span class="font-weight-bold">{{
                    assetType === 'laborer'
                      ? asset.LABORER_NAME
                      : asset.EQUIPMENT_NAME
                  }}</span>
                </template></AssetOverviewList
              >
              <AssetOverviewList
                :assets="availableAssets(allAssets, assignedAssets)"
                title="Available"
                ><template slot="asset" slot-scope="{ asset }">
                  <VIcon small left>{{
                    assetType === 'laborer' ? 'account_circle' : 'settings'
                  }}</VIcon>
                  <span>{{
                    assetType === 'laborer'
                      ? asset.LABORER_NAME
                      : asset.EQUIPMENT_NAME
                  }}</span>
                </template></AssetOverviewList
              >
            </template>
          </VContainer>
        </WithAssignedAssets>
      </div>
    </WithAllAssetsOfType>
  </div>
</template>

<script>
import WithAllAssetsOfType from '@dataProviders/WithAllAssetsOfType'
import WithAssignedAssets from '@dataProviders/WithAssignedAssets'
import AssetOverviewList from '@dispatcher/AssetOverviewList'
import { HumanFields, AssetObjectNames } from '@constants/knack'

export default {
  name: 'AssetAssignmentOverview',
  components: { WithAllAssetsOfType, WithAssignedAssets, AssetOverviewList },
  props: {
    assetType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {
    filterByExclusionFromDispatcher() {
      let filterRules = [
        {
          field:
            HumanFields[AssetObjectNames[this.assetType]]
              .EXCLUDE_FROM_DISPATCHER,
          operator: 'is not',
          value: 'Yes',
        },
      ]
      filterRules.push({
        field: HumanFields[AssetObjectNames[this.assetType]].STATUS,
        operator: 'is',
        value: 'Active',
      })

      return filterRules
    }, // filterByExclusionFromDispatcher
  },
  methods: {
    // TODO (ES) : Add this to dispatcherAssets.js getters somehow.
    // couldn't figure it out using mapGetters
    availableAssets(allAssets, assignedAssets) {
      let assignmentKey = this.assetType === 'laborer' ? 'LABORER' : 'EQUIPMENT'
      let assignedIds = assignedAssets.map((a) => a[assignmentKey][0].id)
      let diff = allAssets.filter((a) => !assignedIds.includes(a.ID))
      return diff
    }, // availableAssets
  },
}
</script>
